// import '@wordpress/api-fetch';
import $ from 'jquery';
import { setLoading, unsetLoading } from './index';

const { giftupData } = window;

/**
 * @global giftupData
 * @global {{
  "products": [
    {
      "id": "44a68084-b1ea-45aa-0517-08d848487852",
      "label": "1"
    },
    {
      "id": "86e170ed-aa25-4479-0519-08d848487852",
      "label": "2"
    },
    {
      "id": "bbc3ecec-79ac-47c0-051a-08d848487852",
      "label": "3"
    },
    {
      "id": "3f1259a1-39eb-4d6b-051b-08d848487852",
      "label": "4"
    }
  ],
  "company": "b9aec172-11ae-4d59-9965-843c8744c061",
  "whofor": "onlyme",
  "promocode": "",
  "hideartwork": "1"
}} giftupData
 */
/**
 * Carico lo script di giftup
 */
( function( g, i, f, t, u, p, s ) {
	g[ u ] = g[ u ] || function() {
		( g[ u ].q = g[ u ].q || [] ).push( arguments );
	};
	p = i.createElement( f );
	p.async = 1;
	p.src = t;
	s = i.getElementsByTagName( f )[ 0 ];
	s.parentNode.insertBefore( p, s );
}( window, document, 'script', 'https://cdn.giftup.app/dist/gift-up.js', 'giftup' ) );

/**
 * Da chiamare quando è statao caricato il checkout.
 *
 * @param {string} option Quale delle opzioni è stata caricata.
 * @returns {boolean}
 */
const onCheckoutLoaded = ( option ) => {
	/**
	 * Con un espressione regolare verifico se l'url è già impostato.
	 *
	 * @type {RegExpMatchArray}
	 */
	const match = window.location.pathname.match( /\/esperienze\/(?:[A-z0-9-]*)\/compra\/([A-z0-9]*)(\/?)$/ );

	if ( ! match ) {
		window.history.pushState( {
			origin: 'js',
			context: 'checkout',
			checkout: true,
			option: option,
		}, undefined, 'https://' + window.location.host + window.location.pathname + 'compra/' + option + '/' + window.location.hash );
	}

	document.body.classList.add( 'doing-checkout' );

	return true;
};

/**
 * Mostra l'upselling e nasconde il checkout.
 */
const showThankYou = () => {
	$( '#buy-container' ).collapse( 'hide' );
	$( '#thank-you-container' ).collapse( 'show' );
};

/**
 * Nasconde l'upselling e mostra il checkout.
 */
const hideThankYou = () => {
	$( '#buy-container' ).collapse( 'show' );
	$( '#thank-you-container' ).collapse( 'hide' );
};

const makeCheckout = ( option, isId, pushState ) => {
	hideThankYou();

	const container = document.getElementById( 'checkout-container' );

	/**
	 * @external giftupData
	 */
	const p = giftupData.products.filter( a => {
		return isId ? a.id === option : a.label === option;
	} ).pop();

	while ( container.firstElementChild ) {
		if ( container.firstElementChild.dataset.productLabel === p.id && container.firstElementChild.dataset.status !== 'over' ) {
			unsetLoading();
			return onCheckoutLoaded( p.label, true );
		}
		container.removeChild( container.firstChild );
	}

	const giftDiv = createGiftUpTarget( giftupData, p.id );

	// Insersco il div nel container
	container.appendChild( giftDiv );

	// Segno quale opzione del prodotto è stata mostrata/inserita.
	container.dataset.productLabel = p.label;

	return onCheckoutLoaded( p.label, pushState ); // giftup( 'load', onCheckoutLoaded );
};

/**
 * Crea da cui GiftUp prenderà per le informazioni per l'iframe.
 *
 * @link https://help.giftup.com/article/31-customizing-the-checkout-experience
 * @param prodotto
 * @returns {boolean|HTMLDivElement}
 */
const createGiftUpTarget = ( prodotto, id ) => {
	setLoading();

	if ( typeof prodotto === 'undefined' ) {
		console.error( 'Non è definito il prodotto' );
		return false;
	}

	if ( typeof id === 'undefined' || typeof id === 'undefined' ) {
		console.error( 'Non è definito il prodotto' );
		return false;
	}

	const giftDiv = document.createElement( 'div' );

	giftDiv.classList.add( 'gift-up-target', 'turbo-giftup-checkout' );

	giftDiv.dataset.productId = id || prodotto.products[ 0 ].id;
	giftDiv.dataset.siteId = prodotto.company || 'b9aec172-11ae-4d59-9965-843c8744c061';
	giftDiv.dataset.hideArtwork = prodotto.hideartwork ? 'true' : 'false';
	giftDiv.dataset.whoFor = prodotto.whofor;

	return giftDiv;
};

/**
 * User type definition
 *
 *
 * @typedef GiftCard
 * @property {string} id
 * @property {string} code
 * @property {string} sku
 * @property {decimal} price
 *
 * @typedef {Object} GiftUpConversionEvent
 * @property {string} email
 * @property {GiftCard[]} giftCards
 * @property {string} orderId
 * @property {string} currency
 * @property {decimal} revenue
 * @property {string} instanceNumber
 */

/**
 * Crea il checkout partendo dal submit di un form.
 *
 * Deve essere necessariamente generata dall'evento submit di un form.
 * @param {Event} event
 * @property {EventTarget} event.target
 */
export const showCheckOut = ( event ) => {
	event.preventDefault();

	const id = event.target.getElementsByClassName( 'checkout-id-selector' )[ 0 ].value;

	return makeCheckout( id, true );
};
/**
 *
 * @param { GiftUpConversionEvent } event
 */
export const handleConversion = ( event ) => {
	const { giftCards } = event;

	document.getElementById( 'buy-container' ).dataset.status = 'over';

	showThankYou();

	/**
	 * è l'elemento in cui possiamo inserire del testo.
	 *
	 * Lo gestiamo tramite JS perchè possiamo
	 * @type {HTMLElement}
	 */
	const freeText = document.getElementById( 'afterbuy-free-text' );

	if ( freeText && giftCards ) {
		const qtaGC = giftCards.length;
		let t = '';

		if ( qtaGC === 1 ) {
			t += 'Il tuo voucher con codice ' + giftCards[ 0 ].code + ' arriverà sulla mail che hai indicato.';
		} else {
			t += 'I tuoi voucher arriveranno sulla mail che hai indicato.';
		}

		t += '<br/>Porta il voucher al nostro partner... etc...';

		freeText.innerHTML = t;
	}
};

document.addEventListener( 'DOMContentLoaded', () => {
	const listenTos = document.querySelectorAll( '.trigger-checkout' );

	// listenTos.forEach( listenTo => listenTo.addEventListener( 'submit', showCheckOut ) );

	document.querySelectorAll( '.close-checkout' ).forEach( b => b.addEventListener( 'click', e => {
		e.preventDefault();

		document.body.classList.remove( 'doing-checkout' );

		const { state } = window.history;

		if ( state && state.origin !== 'js' ) {
			window.history.back();
		} else {
			window.history.pushState( {
				origin: 'js',
				context: 'checkout',
				checkout: false,
			}, document.title, e.target.href );
		}
	} ) );

	window.onpopstate = ( e ) => {
		if ( e.state && e.state.context === 'checkout' ) {
			if ( e.state.checkout ) {
				if ( document.getElementById( 'checkout-container' ).dataset.productLabel === e.state.option ) {
					document.body.classList.add( 'doing-checkout' );
				} else {
					const label = e.target.location.pathname.match( /\/esperienze\/(?:[A-z0-9-]*)\/compra\/([A-z0-9]*)\/$/ )[ 1 ];

					if ( ! label ) {
						console.error( 'Nessuna etichetta trovata' );
					}

					makeCheckout( label, false, false );
				}
			} else {
				document.body.classList.remove( 'doing-checkout' );
			}
		} else if ( e.target.location.pathname === window.location.pathname.replace( /\/esperienze\/(?:[A-z0-9-]*)\/compra\/([A-z0-9]*)\/$/i, '\/esperienze\/$1' ) ) {
			document.body.classList.remove( 'doing-checkout' );
		}
	};
} );

/**
 * @link https://help.giftup.com/article/136-conversion-tracking-via-javascript
 */
giftup( 'load', e => console.debug( 'loaded', e ) );
giftup( 'load', () => unsetLoading() );
giftup( 'page', console.debug );
giftup( 'conversion', handleConversion );
