import $ from 'jquery';
import 'bootstrap';

/**
 * Asggiunge al body la classe turbo-loading
 */
export const setLoading = () => document.body.classList.add( 'turbo-loading' );

/**
 * Rimuove dal body la classe turbo-loading
 */
export const unsetLoading = () => document.body.classList.remove( 'turbo-loading' );

/**
 * Imposta sul megamenu la proprietà maxHeight calc(100vh - l'altezze della navbar)
 */
const setMegamenuMaxHeight = () => {
	const header = document.getElementById( 'site-nav' );

	const bodyMarginTop = header.offsetHeight;

	const mmContainer = document.getElementById( 'megamenu-container' );
	if ( mmContainer ) {
		mmContainer.style.maxHeight = 'calc( 100vh - ' + bodyMarginTop.toString() + 'px )';
	}
};

/**
 * Qui i vari eventi in cui ricalcolare l'altezza del megamenu.
 */
window.addEventListener( 'resize', setMegamenuMaxHeight );
document.addEventListener( 'readystatechange', setMegamenuMaxHeight );
// Questa è superflua ma può essere utile per la retrocompatibilità con i browser.
document.addEventListener( 'load', setMegamenuMaxHeight );

/**
 * @todo rimuovere il countdown.
 */
$( function() {
	/* =========================================
			COUNTDOWN 1
		 ========================================= */
	$( '#clock' ).countdown( '2020/6/30' ).on( 'update.countdown', function( event ) {
		$( this ).html( event.strftime( '' +
			'<span class="h1 font-weight-bold">%D</span> Giorni' +
			'<span class="h1 font-weight-bold">%H</span> Ore' +
			'<span class="h1 font-weight-bold">%M</span> Min' +
			'<span class="h1 font-weight-bold">%S</span> Sec' ) );
	} );

	/* =========================================
			COUNTDOWN 2
		 ========================================= */
	$( '#clock-a' ).countdown( '2020/6/30' ).on( 'update.countdown', function( event ) {
		$( this ).html( event.strftime( '' +
			'<span class="h1 font-weight-bold">%w</span> week%!w' +
			'<span class="h1 font-weight-bold">%D</span> Giorni' ) );
	} );

	/* =========================================
			COUNTDOWN 3
		 ========================================= */
	$( '#clock-b' ).countdown( '2020/6/30' ).on( 'update.countdown', function( event ) {
		$( this ).html( event.strftime( '' +
			'<div class="holder m-2"><span class="h1 font-weight-bold">%D</span> Giorni</div>' +
			'<div class="holder m-2"><span class="h1 font-weight-bold">%H</span> Ore</div>' +
			'<div class="holder m-2"><span class="h1 font-weight-bold">%M</span> Min</div>' +
			'<div class="holder m-2"><span class="h1 font-weight-bold">%S</span> Sec</div>' ) );
	} );

	/* =========================================
			COUNTDOWN 4
		 ========================================= */
	function get15dayFromNow() {
		return new Date( new Date().valueOf() + ( 15 * 24 * 60 * 60 * 1000 ) );
	}

	$( '#clock-c' ).countdown( get15dayFromNow(), function( event ) {
		$( this ).html( event.strftime( '' +
			'<span class="h1 font-weight-bold">%D</span> Giorni%!d' +
			'<span class="h1 font-weight-bold">%H</span> Ore' +
			'<span class="h1 font-weight-bold">%M</span> Min' +
			'<span class="h1 font-weight-bold">%S</span> Sec' ) );
	} );

	/* =========================================
			CALL TO ACTIONS FOR COUNTDOWN 4
		 ========================================= */
	$( '#btn-reset' ).click( function() {
		$( '#clock-c' ).countdown( get15dayFromNow() );
	} );
	$( '#btn-pause' ).click( function() {
		$( '#clock-c' ).countdown( 'pause' );
	} );
	$( '#btn-resume' ).click( function() {
		$( '#clock-c' ).countdown( 'resume' );
	} );
} );

/**
 * Importiamo i file del checkout
 */
import { showCheckOut } from './checkout';

window.turbo = {
	showCheckOut,
};
